import React from "react";
import Skeleton from "react-loading-skeleton";
import { SERVER_HOST } from "../../../api/utils";
import styles from "./styles.module.css";

const Image = ({ src, alt, ...props }) => {
  return (
    <div className={styles.imgWrapper}>
      <img src={`${SERVER_HOST}${src}`} {...props} alt={alt} />
    </div>
  );
};
export const ImageWithoutWrapper = ({ src, alt, ...props }) => {
  return (
    <div className={styles.img}>
      <img src={`${SERVER_HOST}${src}`} {...props} alt={alt} />
    </div>
  );
};
export const SimpleImage = ({ src, alt, ...props }) => {
  return <img src={`${SERVER_HOST}${src}`} {...props} alt={alt} />;
};
export const ImageWithoutStyles = ({ src, alt, ...props }) => {
  return (
    <div className={styles.imageWithoutStyles}>
      <img src={`${SERVER_HOST}${src}`} {...props} alt={alt} />
    </div>
  );
};

export const ImageSkelton = ({ ...props }) => (
  <div className={styles.imgWrapper}>
    <Skeleton />
  </div>
);
export default Image;
