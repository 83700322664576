import React, { useState } from "react";
import style from "./SearchBar.module.css";
import searchIcon from "./searchIcon.svg";
import close from "./close.svg";
import logo from "./logoImg.png";

import { ImageWithoutWrapper } from "../../components/atoms/Image/Image";
import { Link } from "react-router-dom";

function SearchBar({ placeholder, data, testdrive }) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });
    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  return (
    <div className={style.search}>
      <div className={style.searchInputs}>
        <div className={testdrive ? style.testdriveIcon : style.searchIcon}>
          {testdrive ? (
            <img src={logo} alt="cb" className={style.logoimg} />
          ) : (
            <img src={searchIcon} alt="searchIcon" />
          )}
        </div>
        <input
          type="text"
          placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilter}
        />
        <div className={style.searchIcon}>
          {filteredData.length === 0 ? null : (
            <img
              src={close}
              alt=""
              className={style.clearBtn}
              onClick={clearInput}
            />
          )}
        </div>
      </div>
      {filteredData.length !== 0 && (
        <div
          className={
            testdrive
              ? `${style.dataResult} ${style.testdrive}`
              : style.dataResult
          }
        >
          {filteredData.slice(0, 5).map((value, key) => {
            return (
              <Link
                key={value.id}
                className={style.dataItem}
                to={testdrive ? `/${value.slug}/testdrive` : `/${value.slug}`}
              >
                <div className={style.top}>
                  <ImageWithoutWrapper src={value.main_image} />
                </div>
                <p>{value.name} </p>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SearchBar;
