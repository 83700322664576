import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import error from "./error.png";
import styles from "./styles.module.css";
const Error = () => {
  return (
    <Container>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={error} alt="Error" />
      </div>
      <div className={styles.content}>
        <h1 className={styles.heading}>Page Not Found</h1>
        <p className={styles.blur}>
          Looks like you've followed a broken link or entered a URL that doesn't
          exist on this site
        </p>
        <Link className={styles.link} to="/">
          Back to our site
        </Link>
      </div>
    </Container>
  );
};

export default Error;
