import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useHistory, useParams } from "react-router-dom";

import get from "lodash/get";
import styles from "../model/Model.module.css";
import tdstyles from "./styles.module.css";
import { selectModelData, loadModelFromAPI } from "../model/modelSlice";
import {
  selectActive,
  resetActiveModel,
  setActiveModelTestDrive,
} from "./testDriveSlice";
import { selectUserData } from "../login/loginSlice";
import { changeNumberFormat } from "../../utils";

import Loading from "../../components/atoms/loading/Loading";
import Image from "../../components/atoms/Image/Image";
import { Helmet } from "react-helmet";
import { TestDriveFaq } from "../../components/molecules/faq/Faq";
import Testimonials from "../../components/molecules/Testimonials/Testimonials";
import { selectLocationData } from "../../components/molecules/Location/locationSlice";
import offerstyles from "../offers/Offer.module.css";
import offer from "../offers/SVG/offer.svg";
import { FinanceApi, selectFinanceData } from "../offers/offerSclice";

const TestDrive = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { productId } = useParams();

  const location = useSelector(selectLocationData);

  useEffect(() => {
    dispatch(
      loadModelFromAPI(
        productId,
        location?.lat &&
          location?.lng && {
            lat: location?.lat,
            lng: location?.lng,
          }
      )
    );
  }, [dispatch, productId, location.lat, location.lng]);

  const product = useSelector(selectModelData);

  const [startDate, setStartDate] = useState(new Date());

  const activeProductDetails = useSelector(selectActive);

  useEffect(() => {
    if (product?.id !== activeProductDetails.productId) {
      dispatch(resetActiveModel(product?.id));
    }
  }, [activeProductDetails.productId, dispatch, product?.id]);

  useEffect(() => {
    if (
      product?.dealer &&
      product?.dealer?.id !== activeProductDetails.dealerId
    ) {
      dispatch(
        setActiveModelTestDrive({
          type: "dealerId",
          data: product?.dealer?.id,
        })
      );
    }
  }, [activeProductDetails.dealerId, dispatch, product?.dealer]);

  useEffect(() => {
    if (
      product?.showroom &&
      product?.showroom?.id !== activeProductDetails.showroomId
    ) {
      dispatch(
        setActiveModelTestDrive({
          type: "showroomId",
          data: product?.showroom?.id,
        })
      );
    }
  }, [activeProductDetails.showroomId, dispatch, product?.showroom]);
  const userData = useSelector(selectUserData);

  const checkout = () => {
    if (!get(activeProductDetails, ["date"])) {
      alert("Please select a date");
      history.push(`/${product?.slug}/testdrive`);
      return;
    }
    if (!get(activeProductDetails, ["place"])) {
      alert("Please select a prefered location");
      history.push(`/${product?.slug}/testdrive`);
      return;
    }
    if (
      !get(activeProductDetails, ["dealerId"]) ||
      !get(activeProductDetails, ["showroomId"])
    ) {
      alert("Dealer and Showroom not found ");
      history.push(`/${product?.slug}/testdrive`);
      return;
    }
    if (get(userData, ["session", "access-token"])) {
      history.push(`/TestDriveConfirmation`);
    } else {
      history.push(`/login?redirectTo=TestDriveConfirmation`);
    }
  };
  const dateHandler = (date) => {
    setStartDate(date);
    dispatch(
      setActiveModelTestDrive({
        type: "date",
        data: new Date(date).toLocaleDateString("en-CA"),
      })
    );
  };
  const placeHandler = (place) => {
    dispatch(
      setActiveModelTestDrive({
        type: "place",
        data: place,
      })
    );
  };
  // *************************************************
  useEffect(() => {
    if (!!product?.dealer?.id && !!product?.showroom?.id) {
      const payload = {
        dealer_id: product?.dealer.id,
        showroom_id: product?.showroom.id,
        model_id: product?.id,
        variant_id: product?.variants[0]?.id,
      };
      dispatch(FinanceApi(payload));
    }
  }, [
    dispatch,
    product?.showroom?.id,
    product?.dealer?.id,
    product?.id,
    product?.variants,
  ]);

  const financeData = useSelector(selectFinanceData);

  const getTectDriveLabel = (text, delimiter = " | ") => {
    const parts = text.split(delimiter);

    if (parts.length > 1 && parts[1].trim()) {
      return parts[1].trim();
    }

    return "";
  };

  // ******************************************
  const [itemsToShow, setItemsToShow] = useState(300);
  const showmore = (list) => {
    setItemsToShow(list.length);
  };

  const showless = () => {
    setItemsToShow(300);
  };
  // ******************************************
  return (
    <>
      {product?.id && (
        <Container>
          <Helmet>
            <title>
              Book {product?.name} Test Drive Online From Showrooms Near You -
              Carbae
            </title>
            <meta
              name="description"
              content={`Schedule a test drive of ${product?.name} at Carbae.in. Book test drive of top car brands online. Schedule your test drive at home or at your nearest authorized ${product?.name} showroom.`}
            />
            <meta
              name="keywords"
              content={`${product?.name},Book Test Drive ${product?.name} ,Test Drive ${product?.name} ,Book ${product?.name} Online,Book Test Drive, Test Drive, On Road Price, Online Car Booking, Carbae, Popular Car Brands, Car Dealer, Online Car Dealers, Best Car Dealers, Top Trending Cars, Cars On Road Price, New Released Cars`}
            />
            <script type="application/ld+json">
              {JSON.stringify({
                // "@context": "https://schema.org",
                // "@type": "Product",
                // name: product.name,
                // image: `${SERVER_HOST}${product.main_image}`,
                // description: product.description,

                "@context": "https://schema.org",
                "@type": "FAQPage",
                mainEntity: [
                  {
                    "@type": "Question",
                    name: `What is the on road price of the ${product?.name} ? `,
                    acceptedAnswer: {
                      "@type": "Answer",
                      text: `The on road price of ${product?.name} starts at Rs ${product?.price_starts}  to  Rs ${product?.price_ends} `,
                    },
                  },
                  {
                    "@type": "Question",
                    name: `What are the variants of ${product?.name} ? `,
                    acceptedAnswer: {
                      "@type": "Answer",
                      text: `There are ${
                        product?.variants?.length
                      } variants for ${
                        product?.name
                      } and they are ${product?.variants?.map((n) => n.name)} `,
                    },
                  },
                  {
                    "@type": "Question",
                    name: `What are the colors options available for  ${product?.name} ? `,
                    acceptedAnswer: {
                      "@type": "Answer",
                      text: `${product?.name} is available in ${
                        product?.colors?.length
                      } colors and they are ${product?.colors?.map(
                        (n) => n.name
                      )}`,
                    },
                  },
                ],
              })}
            </script>
          </Helmet>
          <Row>
            <Col md="5">
              <div className={tdstyles.testdrive}>
                <Image
                  src={product?.main_image}
                  alt={product?.name}
                  className={styles.img}
                />
                <h1 className={tdstyles.heading}>{product?.name}</h1>
                <h2 className={`${styles.price} center`}>
                  <span></span>
                  {changeNumberFormat(product?.price_starts)} to{" "}
                  {changeNumberFormat(product?.price_ends)}
                </h2>
                {financeData &&
                  financeData?.finance_mapping?.labels &&
                  getTectDriveLabel(financeData?.finance_mapping?.labels) && (
                    <div className={tdstyles.labelDiv}>
                      <span className={tdstyles.label}>
                        {getTectDriveLabel(
                          financeData?.finance_mapping?.labels
                        )}
                      </span>
                    </div>
                  )}
              </div>
            </Col>
            <Col md="1"></Col>
            {product?.dealer && product?.showroom ? (
              <Col md="6">
                <div className={tdstyles.selection}>
                  <div>
                    <h4 className={tdstyles.h4}>
                      Where Do You Want Your Test Drive ?
                    </h4>
                    <div className={tdstyles.location}>
                      <div
                        onClick={() => placeHandler("Showroom")}
                        className={
                          get(activeProductDetails, ["place"]) === "Showroom"
                            ? `${styles.selected} ${tdstyles.locationbtn}`
                            : `${tdstyles.locationbtn}`
                        }
                      >
                        Showroom
                      </div>
                      <div
                        onClick={() => placeHandler("Home")}
                        className={
                          get(activeProductDetails, ["place"]) === "Home"
                            ? `${styles.selected} ${tdstyles.locationbtn}`
                            : `${tdstyles.locationbtn}`
                        }
                      >
                        Home
                      </div>
                    </div>
                    <div>
                      <h4 className={tdstyles.h4}>Select Date</h4>
                      <div className={tdstyles.datepick}>
                        <DatePicker
                          className={
                            get(activeProductDetails, ["date"])
                              ? styles.selected
                              : ""
                          }
                          selected={startDate}
                          onChange={(date) => dateHandler(date)}
                          onFocus={(e) => e.target.blur()}
                          minDate={new Date()}
                          dateFormat="MMMM d, yyyy "
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button
                      className={tdstyles.selectbtn}
                      onClick={checkout}
                      disabled={
                        !get(activeProductDetails, ["place"]) ||
                        !get(activeProductDetails, ["date"])
                      }
                    >
                      {get(activeProductDetails, ["date"]) &&
                      get(activeProductDetails, ["place"])
                        ? "Proceed "
                        : "Choose  date and location "}
                    </Button>
                  </div>
                </div>
              </Col>
            ) : (
              <Col md="6">
                <div className={tdstyles.selection}>
                  {(!location.lat || !location.lng) && (
                    <h4 className={tdstyles.h4}>
                      Please Enable Your Location And Refresh The Page To
                      TestDrive From Showroom Near You{" "}
                    </h4>
                  )}
                </div>
              </Col>
            )}
          </Row>
          {location.lat && location.lng && !!product?.offers?.length && (
            <div className={styles.offers}>
              <h2 className={offerstyles.offer_heading}>Offers for you </h2>
              {product?.total_offers_upto > 0 && (
                <>
                  <div className={offerstyles.offerTitle}>
                    <img src={offer} alt="close" />
                    <p className={`${offerstyles.offer_text}  `}>
                      Total Offers
                    </p>
                  </div>
                  <div className={offerstyles.paddingContainer}>
                    <p className={offerstyles.offerPrice}>
                      {changeNumberFormat(product?.total_offers_upto)}
                    </p>
                    <p className={offerstyles.offerText}>
                      Get upto a total of{" "}
                      {changeNumberFormat(product?.total_offers_upto)} discount
                      on {product?.name}.
                    </p>
                  </div>
                </>
              )}
            </div>
          )}
          {product?.description && (
            <div className={styles.description}>
              <p>{product?.description.slice(0, itemsToShow)} </p>
              <div className={styles.descBox}>
                {itemsToShow === 300 ? (
                  <p
                    className={styles.showmore}
                    onClick={() => showmore(product?.description)}
                  >
                    Show More
                  </p>
                ) : (
                  <p className={styles.showmore} onClick={showless}>
                    Show Less
                  </p>
                )}
              </div>
            </div>
          )}
          <div id="faq" className={styles.faq}>
            <h2 className={styles.faqHeading}>Frequently Asked Question</h2>
            <TestDriveFaq />
          </div>
          <div>
            <h2 className={styles.faqHeading}>User Testimonials</h2>
            <Testimonials />
          </div>
        </Container>
      )}
      {!product.id && <Loading />}
    </>
  );
};

export default TestDrive;
