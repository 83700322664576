import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchBrand, selectLoading, selectResults } from "./brandSlice";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./Brand.module.css";
import {
  ProdCardSkelton,
  ProductCard,
} from "../../components/molecules/prodCard/ProdCard";
import { selectBrands } from "../home/homeSlice";
import Image from "../../components/atoms/Image/Image";
import Filter from "../home/Filter";
import FilterSvg from "./Filter.svg";
import { Helmet } from "react-helmet";

const Brand = () => {
  const dispatch = useDispatch();
  const { brandId, brandName } = useParams();
  const brands = useSelector(selectBrands);
  const loading = useSelector(selectLoading);
  const results = useSelector(selectResults);
  const [brandname, setBrandName] = useState();
  const [show, setShow] = useState(false);
  const [itemKey, setItemKey] = useState("");

  useEffect(() => {
    dispatch(
      fetchBrand({
        brand_id: brandId,
      })
    );
  }, [dispatch, brandId]);

  useEffect(() => {
    const BrandId = brands.filter(
      (prod) => prod.name.toLowerCase() === brandName.split("+").join(" ")
    );
    setBrandName(BrandId[0]);
  }, [dispatch, brands, brandName]);
  return (
    <>
      <Container className={styles.search}>
        {loading ? (
          <>
            <Row className={styles.models}>
              <Col xs="6" sm="4" className={styles.card}>
                <ProdCardSkelton />
              </Col>
              <Col xs="6" sm="4" className={styles.card}>
                <ProdCardSkelton />
              </Col>
            </Row>
          </>
        ) : (
          <>
            {brandname && results.length > 0 && (
              <>
                <Helmet>
                  <title>
                    {brandname.name} | On Road Price | Offers | Test Drives |
                    Bookings | Carbae
                  </title>
                  {/* <meta name="description" content={brandname.description} /> */}
                  <meta
                    name="keywords"
                    content={`${brandname.name},Test Drive ${
                      brandname.name
                    } , Book ${brandname.name} ,Test Drive ${
                      brandname.name
                    } Online , Book ${brandname.name} Online,${results.map(
                      (res) => res.name
                    )}, ${results.map(
                      (res) => `Test Drive ${res.name} `
                    )},${results.map(
                      (res) => `Book ${res.name} `
                    )}, ${results.map(
                      (res) => `Test Drive ${res.name} Online `
                    )},${results.map(
                      (res) => `Book ${res.name} Online`
                    )},Book Test Drive, Test Drive, On Road Price, Online Car Booking, Carbae, Popular Car Brands, Car Dealer, Online Car Dealers, Best Car Dealers, Top Trending Cars, Cars On Road Price, New Released Cars`}
                  />
                </Helmet>

                <div className={styles.Heading}>
                  <div className={styles.BrandLogo}>
                    <Image
                      variant="top"
                      src={brandname.logo}
                      loading="lazy"
                      alt={brandname.name}
                    />
                  </div>
                  <h1 className={styles.brandName}>{brandname.name}</h1>
                </div>

                <div className={styles.models}>
                  {results.map((product, index) => (
                    <div className={styles.card} key={`${product.id}/${index}`}>
                      <ProductCard product={product} />
                    </div>
                  ))}
                </div>

                <div
                  className={styles.searchButton}
                  onClick={() => {
                    setItemKey("1");
                    setShow(true);
                  }}
                >
                  <img src={FilterSvg} alt="Filter" />
                </div>
              </>
            )}
          </>
        )}
      </Container>
      {show && (
        <Filter
          brand={brandId && [parseInt(brandId)]}
          visibility={show}
          itemKey={itemKey}
          click={() => setShow(false)}
        />
      )}
    </>
  );
};
export default Brand;
