import React from "react";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import insta from "./insta.svg";
import fb from "./fb.svg";
import twitter from "./twitter.svg";
import blog from "./blog.png";

const Footer = () => {
  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.footer_bottom}>
          <Nav>
            <Link to="/terms-and-conditions">Terms and conditions</Link>
          </Nav>

          <Nav>
            <Link to="/privacy-policy">Privacy policy</Link>
          </Nav>
        </div>

        {/* <div className={styles.footer_top}> */}
        <div className="center">
          <p>
            For enquiries :
            <a href="mailto:contact@carbae.in"> contact@carbae.in</a>
          </p>
          <p>Copyright &#169; {new Date().getFullYear()} Carbae.in</p>
        </div>

        <div className={styles.footer_top}>
          {/* icon8 -icons */}
          <div>
            <a href="https://x.com/Carbae_in/">
              <img src={twitter} alt="twitterIcon" className={styles.icon} />
            </a>
            <a href="https://www.instagram.com/carbae.in_/">
              <img src={insta} alt="instaIcon" className={styles.icon} />
            </a>
            <a href="https://www.facebook.com/carbae.in/">
              <img src={fb} alt="fbIcon" className={styles.icon} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://blog.carbae.in/">
              <img src={blog} alt="blogIcon" className={styles.icon} />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Footer;
