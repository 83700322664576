import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  selectModels,
  selectBrands,
  bodyTypes,
  transmissionTypes,
  fuelTypes,
} from "./homeSlice";
import {
  fetchResults,
  selectBody_type,
  selectBrand_id,
  selectBudget,
  selectFuel_types,
  selectTransmission_types,
  setBody_type,
  setBrand_id,
  setBudget,
  setFuel_types,
  setTransmission_types,
} from "./filterSlice";

import { Modal, Accordion, Card, Button } from "react-bootstrap";
import BrandCard from "../../components/molecules/brandCard/BrandCard";

import styles from "./Home.module.css";

import hatchback from "./SVG/HatchBack.svg";
import sedan from "./SVG/Sedan.svg";
import suv from "./SVG/Suv.svg";
import muv from "./SVG/muv.svg";
import minivan from "./SVG/minivan.svg";
import cuv from "./SVG/cuv.svg";
import pickup from "./SVG/pickup.svg";
import luxuary from "./SVG/Luxuary.svg";
import Fuel from "./SVG/Fuel.svg";
import close from "./SVG/close.svg";
import down from "./SVG/down.svg";

import { FuelType, TransmissionType, BodyType, Brand } from "./data";
import { PriceRange } from "../../utils";
import MultiRangeSlider from "../../components/molecules/slider/MultiSlider";

const Filter = ({ visibility, brand, body, bud, click, clear, itemKey }) => {
  const models = useSelector(selectModels);
  const brands = useSelector(selectBrands);
  const transmissionType = useSelector(transmissionTypes);
  const fuelType = useSelector(fuelTypes);
  const bodyType = useSelector(bodyTypes);

  const body_type = useSelector(selectBody_type);
  const brand_id = useSelector(selectBrand_id);
  const budget = useSelector(selectBudget);
  const transmission_types = useSelector(selectTransmission_types);
  const fuel_types = useSelector(selectFuel_types);

  const [show, setShow] = useState(visibility ? visibility : false);

  const [minVal, setMinVal] = useState(budget.start || 300000);
  const [maxVal, setMaxVal] = useState(budget.end || 13000000);

  const dispatch = useDispatch();
  const history = useHistory();
  const Logo = {
    suv,
    hatchback,
    luxuary,
    sedan,
    muv,
    minivan,
    cuv,
    pickup,
  };
  const ResultHandler = () => {
    const filter = { body_type, brand_id, transmission_types, fuel_types };
    dispatch(fetchResults(models, filter, budget));
    click();
    history.push(`/search`);
  };

  const BodyHandler = (data) => {
    if (body_type.indexOf(data) !== -1) {
      const res = body_type.filter((value) => value !== data);
      dispatch(setBody_type(res));
    } else {
      dispatch(setBody_type([...body_type, data]));
    }
  };

  const BrandHandler = (data) => {
    if (brand_id.indexOf(data) !== -1) {
      const res = brand_id.filter((value) => value !== data);
      dispatch(setBrand_id(res));
    } else {
      dispatch(setBrand_id([...brand_id, data]));
    }
  };

  const TransmissionTypeHandler = (data) => {
    if (transmission_types.indexOf(data) !== -1) {
      const res = transmission_types.filter((value) => value !== data);
      dispatch(setTransmission_types(res));
    } else {
      dispatch(setTransmission_types([...transmission_types, data]));
    }
  };
  const FuelTypeHandler = (data) => {
    if (fuel_types.indexOf(data) !== -1) {
      const res = fuel_types.filter((value) => value !== data);
      dispatch(setFuel_types(res));
    } else {
      dispatch(setFuel_types([...fuel_types, data]));
    }
  };

  const budgetHandler = () => {
    const data = { start: minVal, end: maxVal };
    dispatch(setBudget(data));
  };

  const clearAll = useCallback(() => {
    dispatch(setBody_type(""));
    dispatch(setBrand_id(""));
    dispatch(setBudget(""));
    dispatch(setFuel_types(""));
    dispatch(setTransmission_types(""));
    setMinVal(300000);
    setMaxVal(13000000);
  }, [dispatch]);

  const clearInput = () => {
    // clearAll();
    setShow(false);
    click();
  };

  useEffect(() => {
    if (!clear) {
      clearAll();
    }
    if (body) {
      dispatch(setBody_type(body));
    }
    if (brand) {
      dispatch(setBrand_id(brand));
    }
    if (bud) {
      setMinVal(bud.start);
      setMaxVal(bud.end);
      dispatch(setBudget(bud));
    }
  }, [clear, body, brand, bud, clearAll, dispatch]);

  return (
    <>
      <Modal
        show={show}
        size="xl"
        dialogClassName={styles.modalClass}
        onHide={clearInput}
      >
        <Modal.Header>
          <p className={styles.filter}>Filter</p>
          <div className="flex">
            <span className={styles.selectedTextBox} onClick={clearAll}>
              <p className={`${styles.selectedText} ${styles.mousePointer}`}>
                Clear All
              </p>
            </span>
            <img
              src={close}
              alt=""
              className={styles.clearBtn}
              onClick={clearInput}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey={itemKey}>
            <div className={styles.accordian}>
              <Card>
                <ToggleWrapper id="0" name="Budget">
                  {minVal && maxVal && (
                    <ToggleWrapperContent>
                      {PriceRange(minVal, maxVal, 1)}
                    </ToggleWrapperContent>
                  )}
                </ToggleWrapper>
                <CollapseWrapper style={styles.bodyBox} id="0">
                  <CollapseWrapperContent>
                    <MultiRangeSlider
                      min={300000}
                      max={13000000}
                      minVal={minVal}
                      maxVal={maxVal}
                      setMinVal={setMinVal}
                      setMaxVal={setMaxVal}
                      budgetHandler={budgetHandler}
                    />
                  </CollapseWrapperContent>
                </CollapseWrapper>
              </Card>
              <Card>
                <ToggleWrapper id="1" name="Brand">
                  {brand_id &&
                    brand_id.length > 0 &&
                    brand_id.map((id) => (
                      <ToggleWrapperContent key={id}>
                        {Brand[id]}
                      </ToggleWrapperContent>
                    ))}
                </ToggleWrapper>
                <CollapseWrapper style={styles.brands} id="1">
                  {brands.map((brand, index) => (
                    <CollapseWrapperContent
                      key={`${brand.id}${index}`}
                      style={styles.brand}
                      func={brand_id && brand_id.includes(brand.id)}
                      clickFunction={() => BrandHandler(brand.id)}
                    >
                      <BrandCard brand={brand} />
                    </CollapseWrapperContent>
                  ))}
                </CollapseWrapper>
              </Card>
              <Card>
                <ToggleWrapper id="2" name="Body Type">
                  {body_type &&
                    body_type.length > 0 &&
                    body_type.map((id) => (
                      <ToggleWrapperContent key={id}>
                        {BodyType[id]}
                      </ToggleWrapperContent>
                    ))}
                </ToggleWrapper>
                <CollapseWrapper style={styles.bodyBox} id="2">
                  {bodyType.map((type) => (
                    <CollapseWrapperContent
                      key={type}
                      style={styles.bodyType}
                      func={body_type && body_type.includes(type)}
                      clickFunction={() => BodyHandler(type)}
                    >
                      <img
                        src={Logo[type]}
                        alt={type}
                        className={styles.type}
                      />
                      <p className={styles.bodyname}>{BodyType[type]}</p>
                    </CollapseWrapperContent>
                  ))}
                </CollapseWrapper>
              </Card>
              <Card>
                <ToggleWrapper id="3" name="Transmission Type">
                  {transmission_types.length > 0 &&
                    transmission_types.map((id) => (
                      <ToggleWrapperContent key={id}>
                        {TransmissionType[id]}
                      </ToggleWrapperContent>
                    ))}
                </ToggleWrapper>
                <CollapseWrapper style={styles.bodyBox} id="3">
                  {transmissionType.map((type) => (
                    <CollapseWrapperContent
                      key={type}
                      style={styles.bodyType}
                      func={transmission_types.includes(type)}
                      clickFunction={() => TransmissionTypeHandler(type)}
                    >
                      <p className={styles.bodyname}>
                        {TransmissionType[type]}
                      </p>
                    </CollapseWrapperContent>
                  ))}
                </CollapseWrapper>
              </Card>
              <Card>
                <ToggleWrapper id="4" name="Fuel Type">
                  {fuel_types.length > 0 &&
                    fuel_types.map((id) => (
                      <ToggleWrapperContent key={id}>
                        {FuelType[id]}
                      </ToggleWrapperContent>
                    ))}
                </ToggleWrapper>
                <CollapseWrapper style={styles.bodyBox} id="4">
                  {fuelType.map((type) => (
                    <CollapseWrapperContent
                      key={type}
                      style={styles.bodyType}
                      func={fuel_types.includes(type)}
                      clickFunction={() => FuelTypeHandler(type)}
                    >
                      <p className={styles.bodyname}>
                        <img src={Fuel} alt={type} />
                        {FuelType[type]}
                      </p>
                    </CollapseWrapperContent>
                  ))}
                </CollapseWrapper>
              </Card>
            </div>
            <div>
              <Button
                className={styles.searchButton}
                variant="primary"
                onClick={ResultHandler}
              >
                Search
              </Button>
            </div>
          </Accordion>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Filter;

export const ToggleWrapper = (props) => {
  return (
    <Accordion.Toggle as={Card.Header} eventKey={props.id}>
      <span className={styles.typeName}>{props.name}</span>
      <span className={styles.selectedSpan}>
        {props.children}
        <img src={down} alt="downIcon" className={styles.clearBtn} />
      </span>
    </Accordion.Toggle>
  );
};
export const ToggleWrapperContent = (props) => {
  return (
    <span className={styles.selectedTextBox}>
      <p className={styles.selectedText}>{props.children}</p>
    </span>
  );
};

export const CollapseWrapper = (props) => {
  return (
    <Accordion.Collapse eventKey={props.id}>
      <Card.Body>
        <div className={props.style}>{props.children}</div>
      </Card.Body>
    </Accordion.Collapse>
  );
};
export const CollapseWrapperContent = (props) => {
  return (
    <div
      className={
        props.func ? `${styles.selected} ${props.style} ` : `${props.style}`
      }
      onClick={props.clickFunction}
    >
      {props.children}
    </div>
  );
};
