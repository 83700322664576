import React, { useEffect, useState, useCallback } from "react";
import locationImg from "./location.svg";
import styles from "./Location.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  // LocationFailed,
  LocationSuccess,
  selectLocationErr,
} from "./locationSlice";

const Location = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  // const getLocation = useCallback(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         dispatch(
  //           LocationSuccess({
  //             lat: position.coords.latitude,
  //             lng: position.coords.longitude,
  //           })
  //         );
  //       },
  //       (error) => {
  //         console.log(error);
  //         //   if (error.code === error.PERMISSION_DENIED) {
  //         if (error) {
  //           dispatch(
  //             LocationFailed(
  //               "Location services are disabled. Please enable them in your device settings."
  //             )
  //           );
  //           setShow(true);
  //         }
  //       }
  //     );
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //     dispatch(
  //       LocationFailed({
  //         message: "Geolocation is not supported by this browser.",
  //       })
  //     );
  //     setShow(true);
  //   }
  // }, [dispatch]);

  const getLocation = useCallback(() => {
    dispatch(
      LocationSuccess({
        lat: 10.026617,
        lng: 76.308411,
      })
    );
  }, [dispatch]);
  useEffect(() => {
    getLocation();
  }, [getLocation]);

  const error = useSelector(selectLocationErr);

  return (
    <>
      <img
        className={styles.locIcon}
        src={locationImg}
        alt="location"
        onClick={getLocation}
      ></img>
      {show && error.haveError && (
        <div className={styles.modal} onClick={() => setShow(false)}>
          <span onClick={() => setShow(false)} className={styles.btn}>
            &times;
          </span>
          <div className={styles.content}>
            {error.message}
            <div onClick={() => setShow(false)} className={styles.close}>
              Okay
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Location;
