import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Home from "../home/Home";
import Search from "../search/Search";
import Brand from "../brand/Brand";
import Login from "../login/Login";
import Product from "../model/Model";
import Profile from "../profile/Profile";
import Checkout from "../checkout/Checkout";
import OrderSuccess from "../ordersuccess/OrderSuccess";
import Terms from "../terms/terms";
import Privacy from "../privacy/privacy";
import { selectUserData } from "../login/loginSlice";
import { selectModels } from "../home/homeSlice";
import get from "lodash/get";
import Error from "../error/Error";
import { Helmet } from "react-helmet";
import Brands from "../brands/Brands";
import TestDrive from "../testdrive/TestDrive";
import Offer from "../offers/Offers";
import TestDriveConfirmation from "../testDriveConfirmation/TestDriveConfirmation";
import TestDriveSuccess from "../testdriveSuccess/Success";
import Exchange from "../exchange/Exchange";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  const userData = useSelector(selectUserData);
  const models = useSelector(selectModels);

  const isLogin = useCallback(() => {
    if (get(userData, ["session", "access-token"])) {
      return true;
    }
    return false;
  }, [userData]);

  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isLogin() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>
          Carbae - New Car Buying Platform | On Road Price & Offers | Book Test
          Drive
        </title>
        <meta
          name="description"
          content="Carbae.in - Welcome To The New Way To Buy New Cars. Explore Top Car Brands, Find On-Road Prices & Latest Offers. Book Test Drives Online"
        />
        <meta
          name="keywords"
          content={`${models.map(
            (res) => res.name
          )},Book Test Drive, Test Drive, On Road Price, Online Car Booking, Carbae, Popular Car Brands, Car Dealer, Online Car Dealers, Best Car Dealers, Top Trending Cars, Cars On Road Price, New Released Cars`}
        />
      </Helmet>
      <Router>
        <div>
          <ScrollToTop />
          <Header />
          <Switch>
            <Route component={Search} path="/search" exact />
            <Route component={Brand} path="/brand/:brandId/:brandName" exact />
            <Route component={TestDrive} path="/:productId/testdrive" exact />
            <Route component={Brands} path="/brands" exact />
            <Route component={Login} path="/login" exact />
            <Route
              component={Offer}
              path="/:productId/on-road-price/offers"
              exact
            />

            <Route component={Exchange} path="/:productId/exchange" exact />
            <Route component={Terms} path="/terms-and-conditions" exact />
            <Route component={Privacy} path="/privacy-policy" exact />

            <PrivateRoute path="/order-success">
              <OrderSuccess />
            </PrivateRoute>
            <PrivateRoute path="/testdrive-success">
              <TestDriveSuccess />
            </PrivateRoute>
            <PrivateRoute path="/checkout">
              <Checkout />
            </PrivateRoute>
            <PrivateRoute path="/profile">
              <Profile />
            </PrivateRoute>
            <PrivateRoute path="/TestDriveConfirmation">
              <TestDriveConfirmation />
            </PrivateRoute>

            <Route component={Home} path="/" exact />
            <Route component={Error} path="/404" exact />
            <Route component={Product} path="/:productId" exact />

            <Redirect from="*" to="/404" />
          </Switch>
        </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
