import { useHistory } from "react-router-dom";

import get from "lodash/get";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectActiveModel,
  selectModelData,
} from "../../../features/model/modelSlice";
import Image from "../../atoms/Image/Image";
import styles from "./ChoosenCar.module.css";
import Edit from "./edit.svg";

export const ChoosenCar = ({ propValue = [], EditVaraint }) => {
  const product = useSelector(selectModelData);
  const history = useHistory();
  if (!product || !product.variants) {
    history.push(`/`);
  }
  const activeProduct = useSelector(selectActiveModel);
  const activeVariant = get(product, ["variants"], []).find((variant) => {
    return variant.id === activeProduct.variantId;
  });

  const activeColor = get(product, ["colors"], []).find((color) => {
    return color.id === activeProduct.colorId;
  });

  return (
    <div className={styles.component}>
      <div className={styles.image}>
        <Image
          src={activeProduct.colorImage}
          loading="lazy"
          alt={product.name}
        />
      </div>
      <div className={styles.content}>
        <h3 className={styles.bold}>{product.name}</h3>
        <div className={styles.variantEdit}>
          <p>{activeVariant?.name}</p>
          <img onClick={EditVaraint} src={Edit} alt="editIcon" />
        </div>
        <p>
          <span>{activeColor?.name}</span>
        </p>
      </div>
    </div>
  );
};
