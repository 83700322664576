import React from "react";
import styles from "./Colors.module.css";
import { SERVER_HOST } from "../../../api/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveModel,
  setActiveModel,
} from "../../../features/model/modelSlice";
import get from "lodash/get";
const Colors = ({ colors = [] }) => (
  <div>
    <div className={styles.colors}>
      {colors.map((color) => (
        <Color colorObj={color} key={`${color.id}-${color.name}`} />
      ))}
    </div>
  </div>
);

const Color = ({ colorObj, ...props }) => {
  const dispatch = useDispatch();
  const activeProductDetails = useSelector(selectActiveModel);
  const onSelect = (variantId) => {
    dispatch(
      setActiveModel({
        type: "colorId",
        data: colorObj.id,
      })
    );
    dispatch(
      setActiveModel({
        type: "colorName",
        data: colorObj.name,
      })
    );
    dispatch(
      setActiveModel({
        type: "colorImage",
        data: colorObj.display_image,
      })
    );
  };

  return colorObj ? (
    <div
      className={`${styles.swatch} ${
        get(activeProductDetails, ["colorId"]) === colorObj.id
          ? styles.swatchSelected
          : ""
      }`}
      {...props}
      title="Choose Color"
      onClick={onSelect}
    >
      <img
        src={`${SERVER_HOST}${colorObj.display_image}`}
        alt={colorObj.name}
      />
    </div>
  ) : null;
};
export default Colors;
